import React from 'react';
import shortid from 'shortid';
import Accordion from '../CurrentInvoice/Accordion/Accordion';
import ButtonTooltip from '../../../../ButtonTooltip/ButtonTooltip';
import {
  ContentContainer,
  Divider,
  DownloadInvoiceButtonWrapper,
  DownloadInvoiceButton,
  List,
  ListItem,
  ListContentTitle,
  ListContentAmount,
  AnimationTransitions,
  AnimationVariants
} from './styles/ItemContentStyled';

const ItemContent = ({ data, dataDetails }) => {
  const showInvoiceLink = data && data.urlFactura;
  const hasSaldoAnterior = () => {
    let hasSaldoAnterior = false;

    if (data && data.amountPending !== 0) {
      hasSaldoAnterior = true;
    }

    return hasSaldoAnterior;
  };

  return (
    <ContentContainer
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: '100%' },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      <Divider />
      {dataDetails && dataDetails.categories && dataDetails.categories.map((element) => {
        return (
          <>
            <Accordion element={element} itemData={data} itemDetails={dataDetails} saldoAnterior={hasSaldoAnterior()} saldoAnteriorValue={data && data.amountPending} />
          </>
        )
      })}
      {/** TODO: transitory solution if boleta not found
       {
       !showInvoiceLink && (
        <ButtonTooltip
          text='Por ahora no se puede descargar esta boleta. Inténtalo más tarde.'
          tooltipMain
        />
        ) 
      }
      <DownloadInvoiceButtonWrapper>
        <DownloadInvoiceButton
          onClick={() => window.open(`${dataDetails.pdf}`)}
          disabled={!showInvoiceLink}
        >
          Descargar boleta
        </DownloadInvoiceButton>
      </DownloadInvoiceButtonWrapper>
       */}
      {showInvoiceLink && (
        <DownloadInvoiceButtonWrapper>
          <DownloadInvoiceButton
            onClick={() => window.open(`${data.urlFactura}`)}
          >
            Descargar boleta
          </DownloadInvoiceButton>
        </DownloadInvoiceButtonWrapper>
      )}
    </ContentContainer >
  );
};

export default ItemContent;
