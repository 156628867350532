import React, { useState } from 'react';
import shortid from 'shortid';
import Item from './Item';

const BoletasAccordion = ({ data, headerValue }) => {
  const [currentOpen, setCurrentOpen] = useState('');
  const callbackFunc = pos => {
    setCurrentOpen(pos);
  };

  return data.map((item, i) => {
    return (
      <Item
        key={shortid.generate()}
        header={item.title}
        headerAmount={item.headerAmount}
        content={item.content}
        contentValue={item.contentValue}
        currentOpen={currentOpen}
        callback={callbackFunc}
        itemPos={String(i)}
        headerValue={headerValue}
      />
    );
  });
};

export default BoletasAccordion;
