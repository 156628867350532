import styled, { css } from 'styled-components';

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 30px;
  margin: auto;

  ${p => p.isOneInvoice &&
    css`
      width: 100%;
    `
  }

`;

export default AccordionContainer;
