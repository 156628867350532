import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import ItemContainer from './styles/ItemStyled';
import ItemHeading from './ItemHeading';
import ItemContent from './ItemContent';
import Loading from '../../../../Loading/Loading';
import { BoletasService } from '../../../../../services';
import { device } from '../../../../../utils/device';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const Item = ({ data, isOneInvoice }) => {
  const [invoicesLength, setInvoicesLength] = useState(isOneInvoice);
  const [toggled, setToggle] = useState(false);
  const [isLoadingBoletas, setIsLoadingBoletas] = useState(false);
  const [isError, setIsError] = useState(false);
  const [boletasData, setBoletasData] = useState();
  const [boletasEmpty, setBoletasEmpty] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceGenerationDate, setInvoiceGenerationDate] = useState();

  const fetchInvoiceDetails = async () => {
    setIsLoadingBoletas(true);

    try {
      const invoiceId = data.documentId;
      // let transformedGenerationDate = invoiceGenerationDate.replaceAll("/", "-");
      const [YYYY, MM, DD] = data.generationDate.split('-');
      const transformedGenerationDate = `${DD}-${MM}-${YYYY}`;

      setInvoiceId(data.documentId);
      setInvoiceGenerationDate(transformedGenerationDate);

      const [responseService] = await BoletasService.getBoletasDetails(
        invoiceId,
        transformedGenerationDate
      );

      if (responseService) {
        setBoletasData(responseService);
        setBoletasEmpty(false);
      } else {
        setBoletasEmpty(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoadingBoletas(false);
    }
  };

  return (
    <ItemContainer invoicesLength>
      <ItemHeading
        data={data}
        toggled={toggled}
        click={() => {
          setToggle(!toggled);

          if (!toggled) {
            fetchInvoiceDetails();
          }
        }}
      />
      {isLoadingBoletas ? (
        <>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </>
      ) : (
        <AnimatePresence initial={false}>
          {toggled && <ItemContent data={data} dataDetails={boletasData} />}
        </AnimatePresence>
      )}
    </ItemContainer>
  );
};

export default Item;
