import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Text, Button } from '@womcorp/wom-ui-kit';
import { InOutCardResponsive } from '../../../..';
import { device } from '../../../../../utils/device';
import { ReactComponent as WhatsAppIcon } from '../../../../../assets/images/whatsapp-icon.svg';
import { ReactComponent as BoletaErrorIcon } from '../../../../../assets/images/boleta-error.svg';

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  padding-bottom: ${rem('36px')};
  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const CenteredText = styled(Text)`
  text-align: center;
  margin: 0;
`;

const BoletaErrorIconWrapper = styled.div`
  margin: 30px;
  text-align: center;
`;

const CenteredTextBold = styled(CenteredText)`
  margin-bottom: ${rem('16px')};
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
`;

const TextWrapper = styled.div`
  margin: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 768px) {
    margin: 0;
  }
`;

const CardTitle = styled.h4`
  ${(p) =>
    p.desktop &&
    css`
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      @media ${device.tablet} {
        display: none;
      }
    `}

  ${(p) =>
    p.mobile &&
    css`
      display: none;

      @media ${device.tablet} {
        display: flex;
        margin: 20px 0 20px 0;
        font-size: 20px;
        font-weight: 500;
      }
    `}
`;

const ArrowWrapper = styled.div`
  margin-right: 10px;
`;

const ReintentarButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ReintentarButton = styled(Button)`
  margin: 30px;
  padding: 10px;
  width: 50%;
  color: black;
  background-color: #ffffff;
  border-color: #2d1441;

  &:hover {
    background-color: #ffffff;
    border-color: #2d1441;
  }
`;

const WhatsAppContentWrapper = styled.div`
  color: #7c6c8a;
  text-align: center;
  border: 1px solid #ccc4d2;
  border-radius: 6px;
  padding: 10px;
  margin: 0 50px;

  p {
    margin: 10px 0;
  }

  @media(max-width: 768px) {
    margin: 0;
  }
`;

const WhatsAppWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const WhatsAppButton = styled(Button)`
  display: flex;
  border: 2px solid #381451;
  border-radius: 30px;
  padding: 11px 25px;

  span {
    align-self: center;
    margin-left: 4px;
  }

  @media(max-width: 768px) {
    padding: 11px 15px;
    width: max-content;
  }
`;

const WhatsAppLink = styled.a``;

const BoletasError = ({ onRetry }) => {
  const API_BASE_WHATSAPP = 'https://api.whatsapp.com/send?phone=56937400691';

  return (
    <>
      <CardTitle desktop>Mis Boletas</CardTitle>
      <CardTitle mobile>
        <ArrowWrapper>
          {`< `}
        </ArrowWrapper>
        | Mis Boletas
      </CardTitle>
      <AccountWrapper>
        <CustomCardWrapper>
          <BoletaErrorIconWrapper>
            <BoletaErrorIcon />
          </BoletaErrorIconWrapper>
          <TextWrapper>
            <CenteredTextBold>No pudimos cargar tus boletas</CenteredTextBold>
            <CenteredText>
              En estos momentos nuestro servicio está presentando problemas
            </CenteredText>
          </TextWrapper>
          <ReintentarButtonWrapper onClick={() => onRetry()}>
            <ReintentarButton>Reintentar</ReintentarButton>
          </ReintentarButtonWrapper>
          <WhatsAppContentWrapper>
            <p>O escríbenos y te ayudaremos</p>
            <WhatsAppWrapper>
              <WhatsAppLink href={API_BASE_WHATSAPP} target="_blank">
                <WhatsAppButton variant="secondary" size="l">
                  <WhatsAppIcon />
                  <span>WHATSAPP EJECUTIVO</span>
                </WhatsAppButton>
              </WhatsAppLink>
            </WhatsAppWrapper>
            <p>Atención 24 horas</p>
          </WhatsAppContentWrapper>
        </CustomCardWrapper>
      </AccountWrapper>
    </>
  );
};

export default BoletasError;
