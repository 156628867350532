import React from 'react';
import Chart from './Chart/Chart';
import Accordion from './Accordion/Accordion';
import {
  CardWrapper,
  CardTitle,
  ChartWrapper,
  InvoicesWrapper
} from './styled';

const LastInvoices = ({ invoices, boletasDetails }) => {
  return (
    <>
      <CardWrapper>
        <CardTitle>
          Boletas Anteriores
        </CardTitle>
        {/*
        <ChartWrapper>
          <Chart data={invoices} />
        </ChartWrapper>
        */}
        <InvoicesWrapper>
          <Accordion data={invoices} dataDetails={boletasDetails} />
        </InvoicesWrapper>
      </CardWrapper>
    </>
  );
};

export default LastInvoices;