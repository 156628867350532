import React from 'react';
import IconMinus from '../../../../../assets/images/minusInvoice.svg';
import IconPlus from '../../../../../assets/images/plusInvoice.svg';
import { thousandsSeparator } from '../../../../../utils/formatters/numberFormatter';
import { HeadingContainer, MesDesplegar } from './styles/ItemHeadingStyled';

const ItemHeading = ({ data, toggled, click }) => (
  <HeadingContainer onClick={click}>
    <div>
      <h2>{data.month}</h2>
      <p>{`Total: $${thousandsSeparator(data.amountToPay)}`}</p>
    </div>
    <MesDesplegar src={toggled ? IconMinus : IconPlus} />
  </HeadingContainer>
);

export default ItemHeading;
