import styled from 'styled-components';

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  width: 100%;
  h2 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: #2d1441;
    margin-bottom: 3px;
    user-select: none;
  }
  p {
    font-family: 'cerapro';
    color: #2d1441;
    font-size: 16px;
    line-height: 19px;
    user-select: none;
  }
`;

const MesDesplegar = styled.img`
  position: absolute;
  right: 0px;
  top: 0px;
  user-select: none;
`;

export { HeadingContainer, MesDesplegar };
