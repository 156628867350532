import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { rem } from 'polished';
import styled from 'styled-components';
import SEO from '../../../components/seo';
import Loading from '../../../components/Loading/Loading';
import MisBoletas from '../../../components/pages/home/Boletas/MisBoletas';
import BoletasNotFound from '../../../components/pages/home/Boletas/BoletasNotFound/BoletasNotFound';
import BoletasError from '../../../components/pages/home/Boletas/BoletasError/BoletasError';
import LastInvoices from '../../../components/pages/home/LastInvoices/LastInvoices';
import { AuthService, AccountService, BoletasService } from '../../../services';
import { SiteLayout } from '../../../components/Layouts';
import { InOutCardResponsive } from '../../../components';
import { device } from '../../../utils/device';
import GoogleTagManager from '../../../helpers/GoogleTagManager';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomLoadingCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const Boletas = () => {
  const userName = AccountService.getUserName();
  const [boletasData, setBoletasData] = useState();
  const [boletasDataDetails, setBoletasDataDetails] = useState();
  const [boletasEmpty, setBoletasEmpty] = useState(true);
  const [isLoadingBoletas, setIsLoadingBoletas] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceGenerationDate, setInvoiceGenerationDate] = useState();

  const fetchBoletas = async () => {
    setIsLoadingBoletas(true);

    try {
      const [responseService] = await BoletasService.getBoletasData();
      const { lastInvoice } = responseService;
      if (lastInvoice) {
        const [YYYY, MM, DD] = lastInvoice.generationDate.split('-');
        const transformedGenerationDate = `${DD}-${MM}-${YYYY}`;

        setInvoiceId(lastInvoice.documentId);
        setInvoiceGenerationDate(transformedGenerationDate);
        setBoletasData(responseService);
        setBoletasEmpty(false);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoadingBoletas(false);
    }
  };

  useEffect(() => {
    fetchBoletas();

    GoogleTagManager.pushToDataLayer({
      event: 'pagina-virtual',
      page: '/boletas',
      pageName: 'Boletas',
    });
  }, []);

  return (
    <>
      <SEO title="Mis Boletas" />
      <SiteLayout user={userName}>
        {isLoadingBoletas && (
          <>
            <LoadingWrapper>
              <CustomLoadingCardWrapper>
                <Loading />
              </CustomLoadingCardWrapper>
            </LoadingWrapper>
          </>
        )}
        {!isLoadingBoletas && !isError && !boletasEmpty && (
          <MisBoletas
            boletas={boletasData}
            boletasDetails={boletasDataDetails}
            invoiceId={invoiceId}
            invoiceGenerationDate={invoiceGenerationDate}
            onRetry={fetchBoletas}
          />
        )}
        {!isLoadingBoletas && !isError && !boletasEmpty && (
          <LastInvoices
            invoices={boletasData}
            boletasDetails={boletasDataDetails}
          />
        )}
        {!isLoadingBoletas && !isError && boletasEmpty && <BoletasNotFound />}
        {!isLoadingBoletas && isError && (
          <BoletasError onRetry={fetchBoletas} />
        )}
      </SiteLayout>
    </>
  );
};

export default Boletas;
