import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px 0px;
  cursor: pointer;
  user-select: none;
  padding-bottom: 15px;

  p {
    margin-bottom: 0;
  }

  @media (max-width: 1100px) and (min-width: 768px) {
    flex-direction: column;
  }
}
`;

const HeaderTextWrapper = styled.div`
  display: flex;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #E92070;
`;

const HeaderAmountWrapper = styled.div`
  display: flex;
`;

const AmountNumberWrapper = styled.div``;

const AmountNumber = styled.span`
  color: #2D1441;
  font-weight: bold;
`;

const TogglerIcon = styled.img`
  margin-right: 5px;
  transition: transform 0.3s ease;
  &.toggled {
    transform: rotate(180deg);
  }
`;

export {
  Wrapper,
  HeaderTextWrapper,
  HeaderAmountWrapper,
  HeaderText,
  TogglerIcon,
  AmountNumberWrapper,
  AmountNumber
};
