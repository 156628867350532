import React from 'react';
import shortid from 'shortid';
import { thousandsSeparator } from '../../../../../../../utils/formatters/numberFormatter';
import {
  List,
  ListItem,
  ListContentTitle,
  ListContentAmount,
  AnimationVariants,
  AnimationTransitions,
} from './styles/ContentStyled';

const Content = ({ content, contentValue }) => {
  return (
    <List
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={AnimationVariants}
      transition={AnimationTransitions}
    >
      <ListItem key={shortid.generate()} data-testid="list-item">
        <ListContentTitle>
          {content}
        </ListContentTitle>
        <ListContentAmount>
          <span>$ </span>
          {` ${thousandsSeparator(contentValue)}`}
        </ListContentAmount>
      </ListItem>
    </List>
  );
};

export default Content;
