import React, { useState, useEffect } from 'react';
import Header from './Header';
import Content from './Content';
import { AnimatePresence } from 'framer-motion';
import { element } from 'prop-types';

const Item = ({ itemData, header, headerAmount, currentOpen, callback, itemPos }) => {
  const [toggled, setToggle] = useState(false);
  const doCallback = () => {
    callback(itemPos);
    setToggle(!toggled);
  };
  const elementSubcategories = itemData[0].contentDetails.find(element => {
    return element.name === header;
  });

  useEffect(() => {
    if (currentOpen !== '') {
      setToggle(true);
    }
    if (currentOpen !== itemPos) {
      setToggle(false);
    }
  }, [currentOpen]);

  return (
    <div data-testid="accordion-item">
      <Header header={header} headerAmount={headerAmount} toggled={toggled} click={() => doCallback()} />
      {elementSubcategories.subcategories.map(element => {
        return (
          <AnimatePresence initial={false}>
            {toggled && <Content content={element.name} contentValue={element.amount} />}
          </AnimatePresence>
        );
      })}
    </div>
  );
};

export default Item;
