import React, { useEffect, useState } from 'react';
import BoletasAccordionDetails from './BoletasAccordionDetails';
import shortid from 'shortid';
import {
  Content,
  ContentWrapper
} from './styled';

const Accordion = ({ item, itemValue, info }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let finalArray = [];
    let contentTitles = [];
    let contentValues = [];

    info && info.subcategories.forEach(detail => {
      contentTitles.push(detail.name);
      contentValues.push(detail.amount);
    });

    finalArray.push(
      {
        title: item,
        content: contentTitles,
        contentValue: contentValues,
        order: null
      }
    );

    setData(finalArray);
  }, []);

  return (
    <ContentWrapper>
      <Content>
        <BoletasAccordionDetails data={data} headerValue={itemValue} key={shortid.generate()} />
      </Content>
    </ContentWrapper>
  );
};

export default Accordion;
