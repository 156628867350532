import React from 'react';
import { ReactComponent as WhatsAppIcon } from '../../../../../assets/images/whatsapp-icon.svg';
import { ReactComponent as BoletaNotFoundIcon } from '../../../../../assets/images/boleta-empty.svg';
import {
  AccountWrapper,
  CustomCardWrapper,
  CenteredText,
  BoletaNotFoundIconWrapper,
  CenteredTextBold,
  TextWrapper,
  CardTitle,
  ArrowWrapper,
  WhatsAppContentWrapper,
  WhatsAppWrapper,
  WhatsAppButton,
  WhatsAppLink
} from './styled';

const BoletasNotFound = () => {
  const API_BASE_WHATSAPP = 'https://api.whatsapp.com/send?phone=56937400691';

  return (
    <>
      <CardTitle desktop>Mis Boletas</CardTitle>
      <CardTitle mobile>
        <ArrowWrapper>
          {`< `}
        </ArrowWrapper>
        | Mis Boletas
      </CardTitle>
      <AccountWrapper>
        <CustomCardWrapper>
          <BoletaNotFoundIconWrapper>
            <BoletaNotFoundIcon />
          </BoletaNotFoundIconWrapper>
          <TextWrapper>
            <CenteredTextBold>No hay boletas disponibles</CenteredTextBold>
            <CenteredText>
              En estos momentos no hay boletas, vuelve a intentar más tarde
            </CenteredText>
          </TextWrapper>
          <WhatsAppContentWrapper>
            <p>O escríbenos y te ayudaremos</p>
            <WhatsAppWrapper>
              <WhatsAppLink href={API_BASE_WHATSAPP} target="_blank">
                <WhatsAppButton variant="secondary" size="l">
                  <WhatsAppIcon />
                  <span>WHATSAPP EJECUTIVO</span>
                </WhatsAppButton>
              </WhatsAppLink>
            </WhatsAppWrapper>
            <p>Atención 24 horas</p>
          </WhatsAppContentWrapper>
        </CustomCardWrapper>
      </AccountWrapper>
    </>
  );
};

export default BoletasNotFound;
