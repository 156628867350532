import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Text, Button } from '@womcorp/wom-ui-kit';
import { InOutCardResponsive } from '../../../..';
import { device } from '../../../../../utils/device';

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  padding-bottom: ${rem('36px')};
  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const CenteredText = styled(Text)`
  text-align: center;
  margin: 0;
`;

const BoletaNotFoundIconWrapper = styled.div`
  margin: 30px;
  text-align: center;
`;

const CenteredTextBold = styled(CenteredText)`
  margin-bottom: ${rem('16px')};
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
`;

const TextWrapper = styled.div`
  margin: 0 80px 30px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

const CardTitle = styled.h4`
  ${
    p => p.desktop &&
    css`
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;

        @media ${device.tablet} {
          display: none;
        }
    `
    }

  ${
    p => p.mobile &&
    css`
    display: none;

    @media ${device.tablet} {
        display: flex;
        margin: 20px 0 20px 0;
        font-size: 20px;
        font-weight: 500;
    }
    `
    }
`;

const ArrowWrapper = styled.div`
  margin-right: 10px;
`;

const WhatsAppContentWrapper = styled.div`
  color: #7C6C8A;
  text-align: center;
  border: 1px solid #CCC4D2;
  border-radius: 6px;
  padding: 10px;
  margin: 0 50px;

  p {
    margin: 10px 0;
  }

  @media(max-width: 768px) {
    margin: 0;
  }
`;

const WhatsAppWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const WhatsAppButton = styled(Button)`
  display: flex;
  border: 2px solid #381451;
  border-radius: 30px;
  padding: 11px 25px;

  span {
    align-self: center;
    margin-left: 4px;
  }

  @media(max-width: 768px) {
    padding: 11px 15px;
    width: max-content;
  }
`;

const WhatsAppLink = styled.a``;

export {
    AccountWrapper,
    CustomCardWrapper,
    CenteredText,
    BoletaNotFoundIconWrapper,
    CenteredTextBold,
    TextWrapper,
    CardTitle,
    ArrowWrapper,
    WhatsAppContentWrapper,
    WhatsAppWrapper,
    WhatsAppButton,
    WhatsAppLink
};
