import React, { useEffect, useState } from 'react';
import Accordion from './Accordion/Accordion';
import { thousandsSeparator } from '../../../../utils/formatters';
import { AuthService } from '../../../../services';
import { ReactComponent as RefreshIcon } from '../../../../assets/images/refresh-button.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus.svg';
import {
  CardTitle,
  ArrowWrapper,
  CardWrapper,
  TitleContent,
  ContentDivider,
  TotalAmounWrapper,
  TotalAmountTitle,
  TotalAmountNumber,
  TotalAmountText,
  IndividualAmountWrapper,
  SaldoAnteriorTitleWrapper,
  TitleRefreshButton,
  SaldosWrapper,
  SaldoAnteriorWrapper,
  SaldoActualWrapper,
  PlusIconWrapper,
  PagaTuCuentaButton,
  PATWrapper,
  PATText,
  PATButton
} from './styled';
import useUser from '../../../../hooks/useUser';

const MisBoletas = ({ boletas = {}, boletasDetails, invoiceId, invoiceGenerationDate, onRetry }) => {
  const { paymentURL: urlDevetel } = useUser();
  const { lastInvoice } = boletas;
  const [saldoAnterior, setSaldoAnterior] = useState(false);

  useEffect(() => {
    const isSaldoAnterior = hasSaldoAnterior();

    if (isSaldoAnterior) {
      setSaldoAnterior(true);
    }
  }, []);

  const hasSaldoAnterior = () => {
    let hasSaldoAnterior = false;

    if (lastInvoice && lastInvoice.amountPending !== undefined && lastInvoice.amountPending !== 0) {
      hasSaldoAnterior = true;
    }

    return hasSaldoAnterior;
  };

  const handlePagaTuCuentaClick = () => {
    return window.open(urlDevetel, "_blank");
  };

  const getBoletaTitle = () => {
    let content;

    content = 'Total a pagar';

    return content;
  };

  const getBoletaMonth = () => {
    let issueMonth = lastInvoice && lastInvoice.month;
    let content;

    content = `Resumen boleta ${issueMonth}`;

    return content;
  };

  const getTotalAmountValue = () => {
    let content;
    let totalToPay = lastInvoice && lastInvoice.amountToPay;

    content = `$ ${thousandsSeparator(totalToPay)}`;

    return content;
  };

  const getTotalAmountText = () => {
    let amountTextEnd;
    let content;

    if (saldoAnterior) {
      amountTextEnd = lastInvoice && lastInvoice.endDate;

      content = `Deuda calculada hasta el ${amountTextEnd}`;
    } else {
      amountTextEnd = lastInvoice && lastInvoice.endDate;

      content = `Deuda calculada hasta el ${amountTextEnd}`;
    }

    return content;
  };

  const getSaldoAnterior = () => {
    let content;
    let amount = lastInvoice && lastInvoice.amountPending;

    content = `$ ${thousandsSeparator(amount)}`;

    return content;
  };

  const getSaldoTotalMes = () => {
    let amount = lastInvoice && lastInvoice.amount;
    let content;

    content = `$ ${thousandsSeparator(amount)}`;

    return content;
  };

  return (
    <>
      <CardTitle desktop>Mis Boletas</CardTitle>
      <CardTitle mobile>
        <ArrowWrapper>
          {`< `}
        </ArrowWrapper>
        | Mis Boletas
      </CardTitle>
      <CardWrapper>
        <SaldoAnteriorTitleWrapper>
          <TitleContent full>
            {getBoletaMonth()}
          </TitleContent>
          <TitleRefreshButton onClick={() => onRetry()}>
            <RefreshIcon />
            <span>Actualizar</span>
          </TitleRefreshButton>
        </SaldoAnteriorTitleWrapper>
        <ContentDivider full noDashed />
        <TotalAmounWrapper>
          <TotalAmountTitle>
            {getBoletaTitle()}
          </TotalAmountTitle>
          <TotalAmountNumber>
            {getTotalAmountValue()}
          </TotalAmountNumber>
          <TotalAmountText>
          {/*
            {getTotalAmountText()}
          */}
          </TotalAmountText>
          {saldoAnterior &&
            <>
              <SaldosWrapper>
                <SaldoAnteriorWrapper>
                  Saldo anterior
                <span>{getSaldoAnterior()}</span>
                </SaldoAnteriorWrapper>
                <PlusIconWrapper>
                  <PlusIcon />
                </PlusIconWrapper>
                <SaldoActualWrapper>
                  Total del mes
                <span>{getSaldoTotalMes()}</span>
                </SaldoActualWrapper>
              </SaldosWrapper>
            </>
          }
          <PagaTuCuentaButton mobile onClick={() => handlePagaTuCuentaClick()}>
            Pagar tu cuenta ahora
          </PagaTuCuentaButton>
          <IndividualAmountWrapper>
            <Accordion
              invoice={lastInvoice}
              invoiceDetails={boletasDetails}
              invoiceId={invoiceId}
              invoiceGenerationDate={invoiceGenerationDate}
              saldoAnterior={saldoAnterior}
              saldoAnteriorValue={lastInvoice && lastInvoice.amountPending}
            />
          </IndividualAmountWrapper>
        </TotalAmounWrapper>
        {/* <ContentDivider full noDashed bottom />
        <PATWrapper>
          <PATText>
            Inscribe tu cuenta PAT y gana un cargo fijo gratis
          </PATText>
          <PATButton>
            INSCRIBIR
          </PATButton>
        </PATWrapper> */}
      </CardWrapper>
    </>
  );
};

export default MisBoletas;
