import styled from 'styled-components';
import { device } from '../../../../../../../utils/device';
import { motion } from 'framer-motion';

const List = styled(motion.ul)`
  padding: 5px 0 5px 17px;
  list-style: none;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  span {
    color: #2D1441;
    text-decoration: none;
    font-size: 13px;
    transition: all 0.3s ease;

    &:hover {
      cursor: default;
    }
`;

const ListContentTitle = styled.div`
  color: #2D1441;
  font-weight: normal;
  font-size: 16px;

  @media ${device.tablet} {
    width: min-content;
  }
`;

const ListContentAmount = styled.div`
  color: #2D1441;
  font-weight: normal;
  font-size: 16px;
  display: flex;

  span {
    padding-right: 2px;
  }
`;

const AnimationVariants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

const AnimationTransitions = { duration: 0.3 };

export {
  List,
  ListItem,
  ListContentTitle,
  ListContentAmount,
  AnimationVariants,
  AnimationTransitions
};
