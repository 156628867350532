import React, { useState, useEffect } from 'react';
import Header from './Header';
import Content from './Content';
import { AnimatePresence } from 'framer-motion';

const Item = ({ header, headerAmount, content, contentValue, currentOpen, callback, itemPos, headerValue }) => {
  const [toggled, setToggle] = useState(false);
  const doCallback = () => {
    callback(itemPos);
    setToggle(!toggled);
  };

  useEffect(() => {
    if (currentOpen !== '') {
      setToggle(true);
    }
    if (currentOpen !== itemPos) {
      setToggle(false);
    }
  }, [currentOpen]);

  return (
    <div data-testid="accordion-item">
      <Header header={header} headerAmount={headerAmount} headerValue={headerValue} toggled={toggled} click={() => doCallback()} />
      <AnimatePresence initial={false}>
        {toggled && <Content content={content} contentValue={contentValue} />}
      </AnimatePresence>
    </div>
  );
};

export default Item;
