import styled from 'styled-components';
import { InOutCardResponsive } from '../../../../../../components';
import { device } from '../../../../../../utils/device';
import { rem } from 'polished';

const ContentWrapper = styled.div`
  min-width: 220px;
  max-width: 400px;
  margin: 0 auto;

  @media(max-width: 768px) {
    min-width: 0;
  }
`;

const Content = styled.div`
  padding: 0px 15px;

  @media(max-width: 768px) {
    padding: 0;
  }
`;

export { Content, ContentWrapper };
