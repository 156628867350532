import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import BoletasAccordion from './BoletasAccordion/BoletasAccordion';
import {
  Content,
  ContentWrapper
} from './styled';

const Accordion = ({ invoice, invoiceId, invoiceGenerationDate, saldoAnterior, saldoAnteriorValue, invoiceDetails }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let finalArray = [];
    let contentTitles = [];
    let contentValues = [];

    invoiceDetails && invoiceDetails.categories.forEach(detail => {
      contentTitles.push(detail.name);
      contentValues.push(detail.amount);
    });

    finalArray.push(
      {
        title: 'Ver detalles boleta',
        content: contentTitles,
        contentValue: contentValues,
        contentDetails: invoiceDetails && invoiceDetails.categories,
        pdf: invoice && invoice.urlFactura,
        dueDate: invoice && invoice.dueDate,
        amountToPay: invoice && invoice.amountToPay
      }
    );

    setData(finalArray)
  }, [saldoAnterior]);

  return (
    <ContentWrapper>
      <Content>
        <BoletasAccordion data={data} invoiceId={invoiceId} invoiceGenerationDate={invoiceGenerationDate} />
      </Content>
    </ContentWrapper>
  );
};

export default Accordion;
