import styled, { css } from 'styled-components';

const ItemContainer = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #ccc4d2;
  padding: 15px;
  margin-bottom: 20px;

  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 40%;
  max-width: 298px;
  margin-right: 28px;
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }
  @media (max-width: 1020px) {
    max-width: 100%;
  }

  ${p => p.invoicesLength &&
    css`
      width: 100%;
    `
  }
`;

export default ItemContainer;
