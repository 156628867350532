import styled from 'styled-components';
import { motion } from 'framer-motion';

const ChartsContainer = styled(motion.div)`
  .tooltip-month {
    font-size: 8px;
  }
  .tooltip-value {
    font-size: 12px;
  }
  width: 480px;

  @media (max-width: 480px) {
    width: 320px;
  }
`;

export default ChartsContainer;
