import React from 'react';
import ToggleIcon from '../../../../../../../assets/images/arrow-icon-chevron.svg';
import { thousandsSeparator } from '../../../../../../../utils/formatters/numberFormatter';
import {
  Wrapper,
  HeaderTextWrapper,
  HeaderAmountWrapper,
  HeaderText,
  AmountNumberWrapper,
  AmountNumber,
  TogglerIcon
} from './styles/HeaderStyled';

const Header = ({ header, headerAmount, click, toggled }) => {
  const getHeaderAmount = () => {
    return (
      <HeaderAmountWrapper>
        <span>$ </span>
        {thousandsSeparator(headerAmount)}
      </HeaderAmountWrapper>
    );
  };

  return (
    <Wrapper onClick={click}>
      <HeaderTextWrapper>
        <TogglerIcon
          data-testid="toggler-icon"
          className={`${toggled ? 'toggled' : ''}`}
          src={ToggleIcon}
        />
        <HeaderText>{header}</HeaderText>
      </HeaderTextWrapper>
      <AmountNumberWrapper>
        <AmountNumber>{getHeaderAmount()}</AmountNumber>
      </AmountNumberWrapper>
    </Wrapper>
  );
};

export default Header;
