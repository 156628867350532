import React from 'react';
import AccordionDetails from '../BoletasAccordionDetails/AccordionDetails';
import ButtonTooltip from '../../../../../ButtonTooltip/ButtonTooltip';
import shortid from 'shortid';
import { thousandsSeparator } from '../../../../../../utils/formatters';
import {
  List,
  AnimationVariants,
  AnimationTransitions,
  FinalAmountWrapper,
  VerBoletaButtonWrapper,
  VerBoletaButton,
  VencimientoText
} from './styles/ContentStyled';

const Content = ({ content, contentValue, dataDetails }) => {
  const showInvoiceLink = dataDetails && dataDetails[0] && dataDetails[0].pdf;

  const getTotalAmountValue = () => {
    let content;
    let totalToPay = dataDetails && dataDetails[0] && dataDetails[0].amountToPay;

    content = `$ ${thousandsSeparator(totalToPay)}`;

    return content;
  };

  const getVencimientoText = () => {
    let vencimientoDay = dataDetails && dataDetails[0] && dataDetails[0].dueDate;

    let content = `Vencimiento: ${vencimientoDay}`;

    return content;
  };

  return (
    <>
      <List
        key={shortid.generate()}
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={AnimationVariants}
        transition={AnimationTransitions}
      >
        {content.map((item, index) => {
          let length = content.length;
          let lastIndex = index + 1;

          return (
            <div key={shortid.generate()}>
              <AccordionDetails
                info={dataDetails[0].contentDetails[index]}
                item={item}
                itemValue={contentValue[index]}
                key={shortid.generate()}
              />
              {lastIndex === length ?
                <div key={shortid.generate()}>
                  <FinalAmountWrapper>
                    Total a pagar:
                    <span> </span>
                    {getTotalAmountValue()}
                  </FinalAmountWrapper>
                  {/**
                 * TODO: transitory solution if boleta not found
                 {!showInvoiceLink && (
                  <ButtonTooltip
                    text='Por ahora no se puede descargar esta boleta. Inténtalo más tarde.'
                    tooltipMain
                  />
                )}
                <VerBoletaButtonWrapper>
                  <VerBoletaButton
                    onClick={() => window.open(showInvoiceLink)}
                    disabled={!showInvoiceLink}
                  >
                    Ver boleta
                   </VerBoletaButton>
                </VerBoletaButtonWrapper>
                 */}
                  {/**TODO: transitory solution if boleta not found */}
                  <VencimientoText style={{ marginTop: `${showInvoiceLink ? '10px' : '30px'}` }}>
                    {getVencimientoText()}
                  </VencimientoText>
                </div>
                : null}
            </div>
          );
        })}
      </List>
      {showInvoiceLink && (
        <VerBoletaButtonWrapper>
          <VerBoletaButton
            onClick={() => window.open(showInvoiceLink)}
          >
            Ver boleta
          </VerBoletaButton>
        </VerBoletaButtonWrapper>
      )}
    </>
  );
};

export default Content;
