import React from 'react';
import shortid from 'shortid';
import { thousandsSeparator } from '../../../../../../utils/formatters';
import {
  List,
  ListItem,
  ListContentTitle,
  ListContentAmount,
  AnimationVariants,
  AnimationTransitions,
} from './styles/ContentStyled';

const Content = ({ content, contentValue }) => {
  return (
    <List
      key={shortid.generate()}
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={AnimationVariants}
      transition={AnimationTransitions}
    >
      {content.map((item, index) => {
        return (
          <ListItem key={shortid.generate()} data-testid="list-item">
            <ListContentTitle>
              {item}
            </ListContentTitle>
            <ListContentAmount>
              <span>$ </span>
              {` ${thousandsSeparator(contentValue[index])}`}
            </ListContentAmount>
          </ListItem>
        );
      })}
    </List>
  );
};

export default Content;
