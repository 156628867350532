import styled from 'styled-components';
import { Button } from '@womcorp/wom-ui-kit';
import { device } from '../../../../../../../utils/device';
import { motion } from 'framer-motion';

const List = styled(motion.ul)`
  padding: 5px 0 5px 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  span {
    color: #2D1441;
    text-decoration: none;
    font-size: 13px;
    transition: all 0.3s ease;

    &:hover {
      cursor: default;
    }
`;

const ListContentTitle = styled.div`
  color: #2D1441;
  font-weight: bold;
  font-size: 16px;

  @media ${device.tablet} {
    width: min-content;
  }
`;

const ListContentAmount = styled.div`
  color: #2D1441;
  font-weight: normal;
  font-size: 16px;
  display: flex;

  span {
    padding-right: 2px;
  }
`;

const AnimationVariants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

const AnimationTransitions = { duration: 0.3 };

const FinalAmountWrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #2D1441;
  display: flex;
  justify-content: flex-end;
  min-width: 210px;
  margin: 10px 23px;

  span {
    padding-left: 30px;
  }

  @media(max-width: 768px) {
    min-width: 0;
  }
`;

const VerBoletaButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const VerBoletaButton = styled(Button)`
  padding: 10px;
  width: 100%;
  color: white;
  background-color: #E92070;
  border-color: #E92070;

  @media(max-width: 768px) {
    width: 100%;
    margin: 30px 0;
  }
`;

const VencimientoText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #2D1441;
  text-align: center;
  margin: 10px auto;
`;

export {
  List,
  ListItem,
  ListContentTitle,
  ListContentAmount,
  AnimationVariants,
  AnimationTransitions,
  FinalAmountWrapper,
  VerBoletaButtonWrapper,
  VerBoletaButton,
  VencimientoText
};
