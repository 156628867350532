import styled, { css } from 'styled-components';
import { Button } from '@womcorp/wom-ui-kit';
import { InOutCardResponsive } from '../../../../components';
import { device } from '../../../../utils/device';

const CardTitle = styled.h4`
  ${p => p.desktop &&
    css`
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;

        @media ${device.tablet} {
          display: none;
        }
    `
  }

  ${p => p.mobile &&
    css`
    display: none;

    @media ${device.tablet} {
        display: flex;
        margin: 20px 0 20px 0;
        font-size: 20px;
        font-weight: 500;
    }
    `
  }
`;

const ArrowWrapper = styled.div`
  margin-right: 10px;
`;

const CardWrapper = styled(InOutCardResponsive)`
  display: flex;
`;

const TitleContent = styled.h4`
  margin-bottom: 20px;
  text-align: center;
  color: #2D1441;
  font-size: 18px;
  font-weight: bold;

  ${p => p.full &&
    css`
      font-size: 24px;
      align-self: center;
      margin-bottom: 0;
    `
  }
`;

const ContentDivider = styled.div`
  border: 1px dashed #CCC4D2;
  width: 50%;
  align-self: center;

  @media ${device.tablet} {
    width: 100%;
  }

  ${p => p.full &&
    css`
      width: 100%;
    `
  }

  ${p => p.noDashed &&
    css`
      border: 1px solid #CCC4D2;
      margin-bottom: 20px;
      `
  }

  ${p => p.bottom &&
    css`
      border: 1px solid #CCC4D2;
      margin: 30px 0 15px 0;
      `
  }
`;

const TotalAmounWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 10px auto;
  padding: 10px;
  width: 100%;
  max-width: 340px;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
`;

const TotalAmountTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #7C6C8A;
  text-transform: uppercase;
  margin: 10px 0;
`;

const TotalAmountNumber = styled.span`
  font-size: 41px;
  font-weight: bold;
  color: #381451;
`;

const TotalAmountText = styled.span`
  font-size: 14px;
  color: #381451;
  margin: 15px 0 15px 0;
  text-align: center;
  padding: 0 15px;

  @media(max-width: 768px) {
    width: 100%;
  }
`;

const IndividualAmountWrapper = styled.div`
  background-color: #F4F4F7;
  border-radius: 8px;
  margin: 10px 0;
  width: 90%;
`;

const FinalAmountWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #2D1441;
  display: flex;
  justify-content: flex-end;
  width: 42%;
  min-width: 210px;
  margin: 0 auto;

  span {
    padding-left: 30px;
  }
`;

const VerBoletaButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const VerBoletaButton = styled(Button)`
  margin: 30px;
  padding: 10px;
  width: 50%;
  color: black;
  background-color: #FFFFFF;
  border-color: #2D1441;

  &:hover {
    background-color: #FFFFFF;
    border-color: #2D1441;
  }

  @media(max-width: 768px) {
    width: 100%;
    margin: 30px 0;
  }
`;

const VencimientoText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #2D1441;
  text-align: center;
  margin-top: 30px;
`;

const SaldoAnteriorTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleRefreshButton = styled(Button)`
  margin: 0 0 10px 0;
  padding: 5px;
  color: black;
  background-color: #FFFFFF;
  border-color: #2D1441;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #FFFFFF;
    border-color: #2D1441;
  }

  @media(max-width: 768px) {
    width: 100%;
    margin: 30px 0;
  }

  span {
    padding-left: 10px;
  }
`;

const SaldosWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #7C6D89;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const SaldoAnteriorWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #2D1441;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
  }
`;

const SaldoActualWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #2D1441;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
  }
`;

const PlusIconWrapper = styled.div`
  margin: 0 10px;
`;

const PagaTuCuentaButton = styled(Button)`
  margin: 15px 0;
  background-color: #E92070;
  border: 1px solid;
  border-radius: 6px;
  color: #FFFFFF;
  align-self: center;
  text-transfrom: uppercase;
  width: 90%;

  ${p => p.mobile &&
    css`
    @media ${device.tablet} {
      width: 100%;
    }
    `
  }
`;

const PATWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PATText = styled.div`
  color: #24152F;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const PATButton = styled(Button)`
  margin: 0 0 0 0;
  padding: 7px;
  color: black;
  background-color: #FFFFFF;
  border-color: #2D1441;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #FFFFFF;
    border-color: #2D1441;
  }

  @media(max-width: 768px) {
    width: 100%;
    margin: 30px 0;
  }

  span {
    padding-left: 10px;
  }
`;

export {
  CardTitle,
  ArrowWrapper,
  CardWrapper,
  TitleContent,
  ContentDivider,
  TotalAmounWrapper,
  TotalAmountTitle,
  TotalAmountNumber,
  TotalAmountText,
  IndividualAmountWrapper,
  FinalAmountWrapper,
  VerBoletaButtonWrapper,
  VerBoletaButton,
  VencimientoText,
  SaldoAnteriorTitleWrapper,
  TitleRefreshButton,
  SaldosWrapper,
  SaldoAnteriorWrapper,
  SaldoActualWrapper,
  PlusIconWrapper,
  PagaTuCuentaButton,
  PATWrapper,
  PATText,
  PATButton
};
