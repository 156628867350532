import React from 'react';
import ToggleIcon from '../../../../../../assets/images/arrow-icon-chevron.svg';
import { thousandsSeparator } from '../../../../../../utils/formatters';
import {
  Wrapper,
  HeaderTextWrapper,
  HeaderAmountWrapper,
  HeaderText,
  TogglerIcon,
  AmountNumberWrapper,
  AmountNumber
} from './styles/HeaderStyled';

const Header = ({ header, headerAmount, headerValue, toggled, click }) => {
  const getHeaderAmount = () => {
    return (
      <HeaderAmountWrapper>
        <span>$ </span>
        {thousandsSeparator(headerValue)}
      </HeaderAmountWrapper>
    );
  };

  return (
    <Wrapper onClick={click}>
      <HeaderTextWrapper>
        <TogglerIcon
          data-testid="toggler-icon"
          className={`${toggled ? 'toggled' : ''}`}
          src={ToggleIcon}
        />
        <HeaderText>{header}</HeaderText>
      </HeaderTextWrapper>
      <AmountNumberWrapper>
        <AmountNumber>{getHeaderAmount()}</AmountNumber>
      </AmountNumberWrapper>
    </Wrapper>
  );
};

export default Header;
