import styled from 'styled-components';
import { InOutCardResponsive } from '../../../../../components';
import { device } from '../../../../../utils/device';
import { rem } from 'polished';

const ContentWrapper = styled.div`
  min-width: 220px;
  max-width: 400px;
  margin: 0 auto;
`;

const Content = styled.div`
  padding: 0px 15px;
`;

export { Content, ContentWrapper };
