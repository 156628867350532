import React, { useEffect, useState } from 'react';
import CurrentInvoiceAccordion from './CurrentInvoiceAccordion/CurrentInvoiceAccordion';
import { Content, ContentWrapper } from './styled';

const Accordion = ({ element, itemDetails, saldoAnterior, saldoAnteriorValue }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Item data: ', itemData)
    // console.log('Item details: ', itemDetails)
    let finalArray = [];
    let contentTitles = [];
    let contentValues = [];

    itemDetails && itemDetails.categories.forEach(detail => {
      contentTitles.push(detail.name);
      contentValues.push(detail.amount);
    });

    finalArray.push(
      {
        title: element.name,
        titleAmount: element.amount,
        content: [contentTitles],
        contentValue: [contentValues],
        contentDetails: itemDetails && itemDetails.categories
      }
    );

    setData(finalArray)
  }, [saldoAnterior]);

  return (
    <ContentWrapper>
      <Content>
        <CurrentInvoiceAccordion itemData={data} />
      </Content>
    </ContentWrapper>
  );
};

export default Accordion;
