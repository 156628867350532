import React from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  margin: 0 auto 10px;
  background-color: #e9ac20;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(5px);
  transition: transform 0.3s, opacity 0.3s;
  display: none;
  &.tooltip-main {
    margin-top: 30px;
    max-width: 300px;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9ac20;
    bottom: -10px;
    position: absolute;
    top: auto;
    left: 31px;
    right: auto;
    margin: auto;
  }
  &.active {
    display: block;
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0px);
  }
  p {
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    @media (max-width: 350px) {
      font-size: 12px;
    }
  }
`;

const ButtonTooltip = ({ text, tooltipMain }) => (
    <TooltipContainer
        className={`button-tooltip active${tooltipMain ? ' tooltip-main' : ''}`}
    >
        <p>{text}</p>
    </TooltipContainer>
);

export default ButtonTooltip;
