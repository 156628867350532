import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import Header from './Header';
import Content from './Content';
import Loading from '../../../../../../components/Loading/Loading';
import { AuthService, BoletasService } from '../../../../../../services';
import { InOutCardResponsive } from '../../../../../../components';
import { device } from '../../../../../../utils/device';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const Item = ({
  header,
  headerAmount,
  content,
  contentValue,
  currentOpen,
  callback,
  itemPos,
  dataDetails,
  invoiceId,
  invoiceGenerationDate,
}) => {
  const [toggled, setToggle] = useState(false);
  const [isLoadingBoletas, setIsLoadingBoletas] = useState(false);
  const [isError, setIsError] = useState(false);
  const [boletasData, setBoletasData] = useState();
  const [boletasEmpty, setBoletasEmpty] = useState(false);

  useEffect(() => {
    if (currentOpen !== '') {
      setToggle(true);
    }
    if (currentOpen !== itemPos) {
      setToggle(false);
    }
  }, [currentOpen]);

  const doCallback = () => {
    callback(itemPos);
    setToggle(!toggled);

    if (!toggled) {
      fetchInvoiceDetails();
    }
  };

  const fetchInvoiceDetails = async () => {
    setIsLoadingBoletas(true);

    try {
      const [responseService] = await BoletasService.getBoletasDetails(
        invoiceId,
        invoiceGenerationDate
      );

      if (responseService) {
        setBoletasData(responseService);
        setBoletasEmpty(false);
      } else {
        setBoletasEmpty(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoadingBoletas(false);
    }
  };

  return (
    <div data-testid="accordion-item">
      <Header
        header={header}
        headerAmount={headerAmount}
        toggled={toggled}
        click={() => doCallback()}
      />
      {isLoadingBoletas ? (
        <>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </>
      ) : (
        <AnimatePresence initial={false}>
          {toggled && (
            <Content
              content={content}
              contentValue={contentValue}
              dataDetails={dataDetails}
            />
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default Item;
