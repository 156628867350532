import React from 'react';
import shortid from 'shortid';
import Item from './Item';
import AccordionContainer from './styles/AccordionStyled';

const Accordion = ({ data, dataDetails }) => {
  const allInvoices = data && data.lastSemester;
  const isOneInvoice = allInvoices && allInvoices.length === 1;
  const items = allInvoices && allInvoices.map(item => {
    return <Item data={item} isOneInvoice={isOneInvoice} key={shortid.generate()} />;
  });

  return <AccordionContainer isOneInvoice>{items}</AccordionContainer>;
};

export default Accordion;
