import styled from 'styled-components';

const ContentWrapper = styled.div`
  width: 45%;
  min-width: 220px;
  max-width: 400px;
  margin: 0 auto;
`;

const Content = styled.div`
  padding: 0px 15px;
  margin-bottom: 15px;
`;

export { Content, ContentWrapper };
