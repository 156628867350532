import styled from 'styled-components';
import { InOutCardResponsive } from '../../../../components';

const CardWrapper = styled(InOutCardResponsive)`
  display: flex;
  margin-top: 25px;
`;

const CardTitle = styled.h4`
  text-align: center;
  color: #2D1441;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`;

const ChartWrapper = styled.div`
  display: flex;
  align-self: center;
`;

const InvoicesWrapper = styled.div`
  display: flex;
`;

export {
    CardWrapper,
    CardTitle,
    ChartWrapper,
    InvoicesWrapper
};
